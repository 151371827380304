import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import HomepageBanner from 'components/homepage-banner'
import SectionText, { SectionTextVariant } from 'components/section-text'
import SectionImageLinks from 'components/section-image-links'
import {
  ContentfulHomepageSection,
  ContentfulPageHomepage,
} from 'types/contentful'

type DataProps = {
  contentfulPageHomepage: ContentfulPageHomepage
}

const HomePage: React.FC<PageProps<DataProps>> = (props) => {
  const { layout, seo, sections, header } = props.data.contentfulPageHomepage

  return (
    <Layout layout={layout} header={header} seo={seo}>
      <HomepageBanner
        contentfulPageHomepage={props.data.contentfulPageHomepage}
      />
      {(sections || []).map(
        (section: ContentfulHomepageSection, index: number) => {
          return (
            <div id={`section-${index + 1}`} key={section.id}>
              {renderSection(section, index)}
            </div>
          )
        }
      )}
    </Layout>
  )
}

const renderSection = (section: ContentfulHomepageSection, index: number) => {
  switch (section.__typename) {
    case 'ContentfulSectionText':
      return (
        <div className="edge-container">
          <SectionText
            index={index}
            variant={SectionTextVariant.Primary}
            {...section}
          />
        </div>
      )
    case 'ContentfulSectionImageLinks':
      return <SectionImageLinks index={index} {...section} />
    default:
      return null
  }
}

export default HomePage

export const pageQuery = graphql`
  query HomepageQuery($contentful_id: String!) {
    contentfulPageHomepage(contentful_id: { eq: $contentful_id }) {
      contentful_id
      layout {
        ...LayoutFields
      }
      header {
        ...HeaderFields
      }
      seo {
        ...SeoFields
      }
      ...HomepageBannerFields
      sections {
        __typename
        ... on ContentfulSectionText {
          ...ContentfulSectionTextFields
        }
        ... on ContentfulSectionImageLinks {
          ...ContentfulSectionImageLinksFields
        }
      }
    }
  }
`
