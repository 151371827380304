import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ContentfulComponentImageLink } from 'types/contentful'
import * as styles from './section-image-links.module.css'

type SectionImageLinksProps = {
  imageLinks: ContentfulComponentImageLink[]
  index: number
}

const SectionImageLinks = ({ imageLinks }: SectionImageLinksProps) => {
  return (
    <div className="edge-container">
      <div className={styles.imageLinks}>
        {imageLinks.map((imageLink) => (
          <a
            key={imageLink.id}
            href={imageLink.url}
            className={styles.imageLink}
          >
            {imageLink.image && (
              <div className={styles.imageWrapper}>
                <GatsbyImage
                  alt={imageLink.image.title || 'Name'}
                  image={imageLink.image.gatsbyImageData}
                  objectFit="cover"
                  objectPosition="center"
                />
              </div>
            )}
          </a>
        ))}
      </div>
    </div>
  )
}

export default SectionImageLinks

export const query = graphql`
  fragment ContentfulSectionImageLinksFields on ContentfulSectionImageLinks {
    id
    imageLinks {
      id
      image {
        gatsbyImageData(layout: CONSTRAINED, height: 120)
      }
      url
    }
  }
`
