import React from 'react'
import { graphql } from 'gatsby'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ContentfulPageHomepage } from 'types/contentful'
import RichText from './rich-text'
import * as styles from './homepage-banner.module.css'

type Props = {
  contentfulPageHomepage: ContentfulPageHomepage
}

const HomepageBanner = ({ contentfulPageHomepage }: Props) => {
  const {
    bannerBackgroundImage,
    bannerHeading,
    bannerContent,
    bannerButtonOneText,
    bannerButtonOneUrl,
  } = contentfulPageHomepage

  const bgImage = bannerBackgroundImage
    ? convertToBgImage(bannerBackgroundImage.gatsbyImageData)
    : null

  return (
    <div className="edge-container">
      <div className={styles.wrapper}>
        {bannerBackgroundImage && (
          <GatsbyImage
            className={styles.mobileBannerImage}
            alt={bannerBackgroundImage.title || 'Banner'}
            image={bannerBackgroundImage.gatsbyImageData}
            objectFit="cover"
            objectPosition="100% 0%"
          />
        )}
        <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
          <div className={styles.leftSide}>
            <div className={styles.bubble}>
              <h1 className={styles.heading}>{bannerHeading}</h1>
              {bannerContent && <RichText data={bannerContent} />}
            </div>
            {bannerButtonOneUrl && (
              <a href={bannerButtonOneUrl} className={styles.button}>
                {bannerButtonOneText}
              </a>
            )}
          </div>
        </BackgroundImage>
      </div>
    </div>
  )
}

export default HomepageBanner

export const query = graphql`
  fragment HomepageBannerFields on ContentfulPageHomepage {
    bannerBackgroundImage {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
    }
    bannerHeading
    bannerContent {
      raw
    }
    bannerButtonOneText
    bannerButtonOneUrl
  }
`
